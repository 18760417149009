* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* homepage css */
.p_tag {
  margin: 0;
  padding: 0;
}
.home_first-section_linex{
  color: white;
  font-size: 20px;
  font-family: 'Source Sans Pro';
  line-height: 54px;
  font-weight: 700;
}
.home_first-section_line1 {
  color: white;
  font-size: 40px;
  font-family: 'Source Sans Pro';
  line-height: 40px;
  font-weight: 300;
}
.home_first-section_line2 {
  color: white;
  font-size: 54px;
  font-family: 'Source Sans Pro';
  line-height: 54px;
  font-weight: 700;
}
.home_first-section_line3 {
  color: white;
  font-size: 40px;
  font-family: 'Source Sans Pro';
  line-height: 40px;
  font-weight: 300;
}
.home_first-section_line4 {
  color: white;
  font-size: 46px;
  font-family: 'Source Sans Pro';
  font-weight: 700;
  line-height: 50px;
}

.common-container {
  padding: 50px 90px;  
}

.footer-title_first-word{
  color: #414042;
  font-size: 32px;
  line-height: 40px;
  font-family: 'Source Sans Pro';
  font-weight:300;
}

.footer-title_second-word{
  color: #414042;
  font-size: 42px;
  font-family: 'Source Sans Pro';
  line-height: 59px;
  font-weight:700;
  letter-spacing: -0.5px;
}


.common-title_first-word {
  color: #414042;
  font-size: 40px;
  line-height: 40px;
  font-family: 'Source Sans Pro';
  font-weight:300;
}
.common-title_second-word {
  color: #414042;
  font-size: 54px;
  font-family: 'Source Sans Pro';
  line-height: 59px;
  font-weight:700;
  letter-spacing: -0.5px;
}

.body-text_non-bold {
  color: #414042;
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  font-family: 'Source Sans Pro';
  letter-spacing: -0.5px;
}

.body-normal-text_non-bold {
  color: #414042;
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  font-family: 'Source Sans Pro';
  letter-spacing: -0.5px;
}

.body-small-text_non-bold {
  color: #414042;
  font-size: 14px;
  font-weight: 300;
  line-height: 25px;
  font-family: 'Source Sans Pro';
  letter-spacing: -0.5px;
}

.body-text_semi-bold {
  color: #414042;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  font-family: 'Source Sans Pro';
  letter-spacing: -0.5px;
}

.body-text_bold {
  color: #414042;
  font-size: 20px;
  font-weight: 900;
  line-height: 25px;
  font-family: 'Source Sans Pro';
  letter-spacing: -0.5px;
}

.title-text-1_non-bold {
  font-size: 30px;
  font-weight: 400;
  font-family: 'Source Sans Pro';
  line-height: 30px;
  letter-spacing: -0.5px;
}
.title-text-1_semi-bold {
  font-size: 30px;
  color: #414042;
  font-weight: 700;
  font-family: 'Source Sans Pro';
  line-height: 30px;
  letter-spacing: -0.5px;
}
.title-text-2_bold {
  font-size: 30px;
  color: #414042;
  font-weight: 700;
  font-family: 'Source Sans Pro';
  line-height: 30px;
  letter-spacing: -0.5px;
}

.footer-text_non-bold {
  color: #414042;
  font-size: 18px;
  font-family: 'Source Sans Pro';
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.5px;
}
.white {
  color: white;
}

.black {
  color: #414042;
}


/* solutions page css */
.common-title2_bold {
  /* color:#414042; */
  font-size: 26px;
  font-family: 'Source Sans Pro';
  line-height: 26px;
  font-weight:700;
  letter-spacing: -0.5px;
}


.common-card-title_bold {
  /* color:#414042; */
  font-size: 26px;
  font-family: 'Source Sans Pro';
  line-height: 26px;
  font-weight:700;
  letter-spacing: -0.5px;
}

/* marcketplace section css */
/* ************************** */

.first-image-top-text{
  text-align: center;
   font-weight: 300;
   font-family: 'Source Sans Pro';
}

.first-image-top-text-title{
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  font-family: 'Source Sans Pro';
}
.marketplace-contracts-line1{
  color: #00DCE2;
  font-family: 'Source Sans Pro';
  font-size: 20px;
  font-weight: 900;
  line-height: 25px;
  }
  .ecosystemText{
    font-size: 30px;
    font-family: 'Source Sans Pro';
    font-weight: 700;
    text-align: center;
  }
 
/* enablers section css */
.enablers-Journey-Heading{
  font-size: 40px;
  font-family: 'Source Sans Pro';
  line-height: 59px;
  font-weight:700;
  letter-spacing: -0.5px;
}

.display-single-line {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media only screen and (max-width: 900px) {
  .common-container {
    padding: 30px 5%;
  }
  .body-text_bold{
    font-size: 16px;
    line-height: 20px;
  }
  .body-text_non-bold{
    font-size: 16px;
    line-height: 20px;
  }
  .common-title_first-word {
    font-size: 27px;
    line-height: 27px;
}
  .common-title_second-word {
    font-size: 33px;
    line-height: 33px;
  }
  .home_first-section_line1 {
    font-size: 27px;
    line-height: 29px;
}
.home_first-section_line2 {
    font-size: 30px;
    line-height: 30px;
}

.home_first-section_line3 {
    font-size: 30px;
    line-height: 30px;
}
.home_first-section_line4 {
    font-size: 30px;
    line-height: 38px;
}
  .footer-text_non-bold{
    font-size: 16px;
    line-height: 16px;
  }
  .home_first-section_linex{
    line-height: normal;
  }
  
  .body-text_semi-bold{
    font-size: 16px;
    line-height: 16px;
  }
  .enablers-Journey-Heading{
    font-size: 26px;
  }
  .common-title2_bold {
    font-size: 18px;
  }
}

@media only screen and (max-width: 768px) {
  .footer-title_first-word{
    font-size: 24px;
    line-height:normal
  }
  .footer-title_second-word{
    font-size: 28px;
    line-height:normal
  }
}
@media only screen and (max-width: 360px) {
  .footer-title_second-word{
    font-size: 26px;
    line-height:normal
  }
}

/* 
.home_first-section_line1 {
    font-size: 27px;
    line-height: 29px;
}
.home_first-section_line2 {
    font-size: 30px;
    line-height: 30px;
}

.home_first-section_line3 {
    font-size: 30px;
    line-height: 38px;
}
.home_first-section_line4 {
    font-size: 30px;
    line-height: 38px;
}
*/
.font30Pxsize{
  font-size: 30px !important;
}
.colorWhiteText{
  color: white !important;
}
.fixedInlinecss{
  position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        width: 100vw !important;
        height: 100vh !important;
        backdrop-filter: blur(5px) !important;
        z-index: 1010 !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
}
.fontSize25pxFor{
  font-size: 25px !important;
}
.fontSize18pxFor{
  font-size: 18px !important;
}
.width100Pecrent{
  width: 100% !important;
}
.cursorPointercls{
  cursor: pointer !important;
}
.cursorDefault{
  cursor: default !important;
}
.perticularinlineforsomeCls{
  position: absolute !important;
  bottom: -5 !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}
.height80pxInline{
  height: 80px !important;
}
.textAlignCenterT{
  text-align: center !important;
}
.textAlignRightT{
  text-align: right !important;
}
.textAlignLeftT{
  text-align: left !important;
}
.heightAutoInlune{
  height: auto !important;
}
.hiddenOverflowcls{
  overflow: hidden !important;
}
.flxDisplayProperty{
  display: flex !important;
}
.centerContextclas{
  display: flex !important;
  justify-content: center !important;
}
.lineHeightclassinline{
  line-height: 38px !important;
}